import React from "react";
import {
  StyledBusinessDivider, StyledBusinessIconTextImage, StyledBusinessIconTextItem, StyledBusinessIconTextLabel, StyledBusinessIconTextText, 
} from "./style";

const BusinessIconTextItem = ({
  htmlText, label, image, maxWidth, variant,
}) => {
  return (
    <StyledBusinessIconTextItem maxWidth={maxWidth}>
      <StyledBusinessIconTextImage src={image} loading="lazy" />
      <StyledBusinessIconTextLabel>
        <p dangerouslySetInnerHTML={{ __html: label }} />
      </StyledBusinessIconTextLabel>
      {htmlText && (
        <>
          <StyledBusinessDivider variant={variant}/>
          <StyledBusinessIconTextText
            dangerouslySetInnerHTML={{ __html: htmlText }}
          />
        </>
      )}
    </StyledBusinessIconTextItem>
  );
};
export default BusinessIconTextItem;
