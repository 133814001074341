import axios from "axios";
import { useContext } from "react";
import { DataContext } from "../contexts/DataContext";

const getPipeformHowKnowUsOptions = (labels) => [{
  id: 62,
  value: labels.pipeformHowKnowUsOptions.searchEngine,
},{
  id: 63,
  value: labels.pipeformHowKnowUsOptions.linkedin,
},{
  id: 64,
  value: labels.pipeformHowKnowUsOptions.facebookInstagraa,
},{
  id: 65,
  value: labels.pipeformHowKnowUsOptions.csrEvent,
},{
  id: 66,
  value: labels.pipeformHowKnowUsOptions.words,
},{
  id: 67,
  value: labels.pipeformHowKnowUsOptions.tvRadio,
},{
  id: 68,
  value: labels.pipeformHowKnowUsOptions.newspaperBlog,
},{
  id: 69,
  value: labels.pipeformHowKnowUsOptions.alreadyPrivateAdopter,
},{
  id: 85,
  value: labels.pipeformHowKnowUsOptions.other,
}];

const getPipeformWhyTerrainOptions = (labels) => [{
  id: 1,
  value: labels.pipeformWhyTerrainOptions.rent,
},{
  id: 2,
  value: labels.pipeformWhyTerrainOptions.sell,
},{
  id: 3,
  value: labels.pipeformWhyTerrainOptions.bio,
},{
  id: 4,
  value: labels.pipeformWhyTerrainOptions.other,
}];

const getLanguage = () => {
  if (window.location.pathname.startsWith("/en")) {
    return 193;
  }
  if (window.location.pathname.startsWith("/es")) {
    return 194;
  }
  if (window.location.pathname.startsWith("/de")) {
    return 195;
  }
  if (window.location.pathname.startsWith("/fr")) {
    return 196;
  }
  return 192;
};

const getAdsTerrain = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("fbclid")) {
    // Facebook
    return 87;
  }
  if (urlParams.has("gclid")) {
    // Google
    return 85;
  }
  if (urlParams.has("li_fat_id")) {
    // Linkedin
    return 84;
  }
  if (urlParams.has("msclkid")) {
    // Microsoft
    return 86;
  }
  
  // No
  return 83;
};

const getAds = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("fbclid")) {
    // Facebook
    return 226;
  }
  if (urlParams.has("gclid")) {
    // Google
    return 224;
  }
  if (urlParams.has("li_fat_id")) {
    // Linkedin
    return 223;
  }
  if (urlParams.has("msclkid")) {
    // Microsoft
    return 225;
  }
  
  // No
  return 222;
};

const getAdsCampaign = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("fbclid")) {
    // Facebook
    return urlParams.get("fbclid");
  }
  if (urlParams.has("gclid")) {
    // Google
    return urlParams.get("gclid");
  }
  if (urlParams.has("li_fat_id")) {
    // Linkedin
    return urlParams.get("li_fat_id");
  }
  if (urlParams.has("msclkid")) {
    // Microsoft
    return urlParams.get("msclkid");
  }
};

const sendPipeform = ({
  name, email, companyName, phone, acceptMarketing, numEmployees, message, howKnowUsId,
}) => {
  const params =  {
    V2ViRm9ybUNhcHR1cmVCbG9jazo5NDMzNWFkMC1mN2E5LTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: name ?? "NA", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazo5NDMzNWFkMS1mN2E5LTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: email ?? "NA@3bee.com", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazpmOTI2YjU0MS1mN2E5LTExZWMtOGU4Ni1kMzg4MDI2NzRkOGM: companyName ?? "NA", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazo5NDMzNWFkMi1mN2E5LTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: phone  ?? "NA", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazpmOTI2YjU0MC1mN2E5LTExZWMtOGU4Ni1kMzg4MDI2NzRkOGM: acceptMarketing ? [50] : [],
    V2ViRm9ybUNhcHR1cmVCbG9jazpmOTI2ZGM1MC1mN2E5LTExZWMtOGU4Ni1kMzg4MDI2NzRkOGM: numEmployees ?? "", 
    V2ViRm9ybUNhcHR1cmVCbG9jazo5NDMzNWFkMy1mN2E5LTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: message ?? "",
    V2ViRm9ybUNhcHR1cmVCbG9jazpjMGE0ZDNkMC0yN2E4LTExZWQtOTY4Yi1mNWZiZWVmYWE1N2Y: message ?? "",
    V2ViRm9ybUNhcHR1cmVCbG9jazpmOTI2ZGM1MS1mN2E5LTExZWMtOGU4Ni1kMzg4MDI2NzRkOGM: howKnowUsId ?? "",
    V2ViRm9ybUNhcHR1cmVCbG9jazpiYTRjYTFmMC1mN2FkLTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: getLanguage(), // required
    V2ViRm9ybUNhcHR1cmVCbG9jazpiYTRjYzkwMC1mN2FkLTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: getAds(), // required
    V2ViRm9ybUNhcHR1cmVCbG9jazpiYTRjYzkwMS1mN2FkLTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: getAdsCampaign() ?? "NA",
    V2ViRm9ybUNhcHR1cmVCbG9jazpiYTRjYzkwMi1mN2FkLTExZWMtYTYxOC1jZDk3ZWNmZmU1NjI: window.location.href,
  };

  /// Definitions: https://webforms.pipedrive.com/f/1HGGAPEq6LcyOvetUpjbT1d9joSmPWObg80T7rVG1PNOeL6oVxVieUXErxkfsATKP/definition
  return axios
    .post(
      "https://webforms.pipedrive.com/f/1HGGAPEq6LcyOvetUpjbT1d9joSmPWObg80T7rVG1PNOeL6oVxVieUXErxkfsATKP",
      {
        values: params,
      },
    );
};

const sendPipeformBox = ({
  name, email, phone, acceptMarketing, shopType, shopAddress, message,
}) => {
  const params =  {
    V2ViRm9ybUNhcHR1cmVCbG9jazo5YjM1ZWM5MS1mYmE5LTExZWMtODY2Yy01ZDUwMGViODAxZTg: name ?? "NA", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazo5YjM1ZWM5Mi1mYmE5LTExZWMtODY2Yy01ZDUwMGViODAxZTg: email ?? "NA@3bee.com", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazo5YjM1ZWM5My1mYmE5LTExZWMtODY2Yy01ZDUwMGViODAxZTg: phone ?? "NA", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazo5YjM1ZWM5NS1mYmE5LTExZWMtODY2Yy01ZDUwMGViODAxZTg: email ?? "NA", // nome azienda
    V2ViRm9ybUNhcHR1cmVCbG9jazo5YjM1ZWM5NC1mYmE5LTExZWMtODY2Yy01ZDUwMGViODAxZTg: acceptMarketing ? [50] : [],
    V2ViRm9ybUNhcHR1cmVCbG9jazo2NDNiMWIxMC1mYmFhLTExZWMtOWUyYy0wMTJiNjNiYmU1NTY: shopType ?? "",
    V2ViRm9ybUNhcHR1cmVCbG9jazo2NDNiMWIxMS1mYmFhLTExZWMtOWUyYy0wMTJiNjNiYmU1NTY: shopAddress ?? "",
    V2ViRm9ybUNhcHR1cmVCbG9jazo5YjM1ZWM5Ny1mYmE5LTExZWMtODY2Yy01ZDUwMGViODAxZTg: message ?? "",
    V2ViRm9ybUNhcHR1cmVCbG9jazozN2ExMmY2MC0yN2E5LTExZWQtOTY4Yi1mNWZiZWVmYWE1N2Y: message ?? "",
    V2ViRm9ybUNhcHR1cmVCbG9jazo5YjM1ZWM5OS1mYmE5LTExZWMtODY2Yy01ZDUwMGViODAxZTg: getLanguage(), // required
    V2ViRm9ybUNhcHR1cmVCbG9jazo5YjM1ZWM5YS1mYmE5LTExZWMtODY2Yy01ZDUwMGViODAxZTg: getAds(), // required
    V2ViRm9ybUNhcHR1cmVCbG9jazo5YjM1ZWM5Yi1mYmE5LTExZWMtODY2Yy01ZDUwMGViODAxZTg: getAdsCampaign() ?? "NA",
    V2ViRm9ybUNhcHR1cmVCbG9jazo5YjM1ZWM5Yy1mYmE5LTExZWMtODY2Yy01ZDUwMGViODAxZTg: window.location.href,
  };
  
  /// Definitions: https://webforms.pipedrive.com/f/1I1gDEqr0rhhbG8OlH4dkCyPABNhKeleWicwJCbyS90yopzi8lgEXfrTGuQotlJ2r/definition
  return axios
    .post(
      "https://webforms.pipedrive.com/f/1I1gDEqr0rhhbG8OlH4dkCyPABNhKeleWicwJCbyS90yopzi8lgEXfrTGuQotlJ2r",
      {
        values: params,
      },
    );
};

const sendPipeformTerrain = ({
  name, email, phone, message, gps, ha, type, province,
}) => {
  const params =  {
    V2ViRm9ybUNhcHR1cmVCbG9jazozYTFkYzQ5MC0xM2QzLTExZWQtOGM4Yi1mNTU5YzYzZThkOGE: name ?? "NA", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazozYTFkYzQ5MS0xM2QzLTExZWQtOGM4Yi1mNTU5YzYzZThkOGE: email ?? "NA@3bee.com", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazozYTFlMTJiMC0xM2QzLTExZWQtOGM4Yi1mNTU5YzYzZThkOGE: phone ?? "NA",
    V2ViRm9ybUNhcHR1cmVCbG9jazpkZjQyZDk3MS0xM2Q3LTExZWQtOGJmOS02ZGY0NTc3ZjA2MTE: gps ?? "",
    V2ViRm9ybUNhcHR1cmVCbG9jazpkZjQyYjI2MC0xM2Q3LTExZWQtOGJmOS02ZGY0NTc3ZjA2MTE: ha?.toString() ?? "0",
    V2ViRm9ybUNhcHR1cmVCbG9jazo3NGU1ZmY4MC0xM2Q3LTExZWQtOGJmOS02ZGY0NTc3ZjA2MTE: message ?? "",
    V2ViRm9ybUNhcHR1cmVCbG9jazo0MDQwODY4MC0xNDE2LTExZWQtOGM4Yi1mNTU5YzYzZThkOGE: type?.toString() ?? "",
    V2ViRm9ybUNhcHR1cmVCbG9jazo5N2ViNTdmMC0zOWNmLTExZWQtYTY1Zi1jMzQyYThjNDJkZTQ: province ?? "",
    V2ViRm9ybUNhcHR1cmVCbG9jazoxNDFjMDVjMC0xNzQwLTExZWQtOGM4Yi1mNTU5YzYzZThkOGE: getAdsTerrain(), // required
    V2ViRm9ybUNhcHR1cmVCbG9jazoxNDFjMmNkMC0xNzQwLTExZWQtOGM4Yi1mNTU5YzYzZThkOGE: getAdsCampaign() ?? "NA",
    V2ViRm9ybUNhcHR1cmVCbG9jazoxNDFjMmNkMS0xNzQwLTExZWQtOGM4Yi1mNTU5YzYzZThkOGE: window.location.href,
  };

  /// Definitions: https://webforms.pipedrive.com/f/1wVwrlM9uzcAdfucrxJk0AAERc5gtCBuWkOD0stlXIifje0kc6HhztOJZXDcCQEhR/definition
  return axios
    .post(
      "https://webforms.pipedrive.com/f/1wVwrlM9uzcAdfucrxJk0AAERc5gtCBuWkOD0stlXIifje0kc6HhztOJZXDcCQEhR",
      {
        values: params,
      },
    );
};

const sendPipeformEducation = ({
  schoolName, name, email, phone, message, acceptMarketing,
}) => {
  const params =  {
    V2ViRm9ybUNhcHR1cmVCbG9jazo0ZmU4MjlhMi1iYjIyLTExZWMtYjc5Ni05Mzk4ODcwZWIxMzA: schoolName ?? "NA", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazoxMjk1Y2YyMC1iYjIzLTExZWMtOTE5MS1lYjg4MmM2MGZmNjU: name ?? "NA", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazo0ZmU4MjlhMy1iYjIyLTExZWMtYjc5Ni05Mzk4ODcwZWIxMzA: email ?? "NA@3bee.com", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazo0ZmU4MjlhNC1iYjIyLTExZWMtYjc5Ni05Mzk4ODcwZWIxMzA: phone ?? "NA", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazo0ZmU4MjlhNi1iYjIyLTExZWMtYjc5Ni05Mzk4ODcwZWIxMzA: message ?? "", // required
    V2ViRm9ybUNhcHR1cmVCbG9jazo0ZmU4NzdjMC1iYjIyLTExZWMtYjc5Ni05Mzk4ODcwZWIxMzA: [49],
    V2ViRm9ybUNhcHR1cmVCbG9jazo0ZmU4NzdjMS1iYjIyLTExZWMtYjc5Ni05Mzk4ODcwZWIxMzA: acceptMarketing ? [50] : [],
    V2ViRm9ybUNhcHR1cmVCbG9jazpjNzIzOWRlMC1hNjJlLTExZWQtOThmNS1kOWUyMzc1ZjBjNjQ: getLanguage(),
    V2ViRm9ybUNhcHR1cmVCbG9jazo3YzIyNmExMC1hNjJlLTExZWQtOThmNS1kOWUyMzc1ZjBjNjQ: window.location.href,
  };

  /// Definitions: https://webforms.pipedrive.com/f/1yOoEhuriLTFHbLR8E7r6iafBKABFJhj0wNM1lm74xkDgazJB4wwK8ytoXSnWtA8r/definition
  return axios
    .post(
      "https://webforms.pipedrive.com/f/1yOoEhuriLTFHbLR8E7r6iafBKABFJhj0wNM1lm74xkDgazJB4wwK8ytoXSnWtA8r",
      {
        values: params,
      },
    );
};

const usePipeform = () => {
  const { labels } = useContext(DataContext);

  return {
    pipeformHowKnowUsOptions: getPipeformHowKnowUsOptions(labels),
    sendPipeform,
    sendPipeformBox,
    sendPipeformTerrain,
    sendPipeformEducation,
    pipeformWhyTerrainOptions: getPipeformWhyTerrainOptions(labels),
  };
};

export default usePipeform;
