import React, { useContext } from "react";
import ContactFormPipe from "../../components/ContactFormPipe/ContactFormPipe";
import Intro from "../../components/Intro_v2/Intro_v2";
import Meta from "../../components/Meta/Meta";
import Spacer from "../../components/Spacer/Spacer";
import SectionTwoCol from "../../components/SectionTwoCol/SectionTwoCol";
import { DataContext } from "../../contexts/DataContext";
import Hero3BC from "../../components/Hero3BC/Hero3BC";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import BusinessSlider from "../../components/BusinessSlider/BusinessSlider";
import BusinessIconText from "../../components/BusinessIconText/BusinessIconText";
import BusinessClients from "../../components/BusinessClients/BusinessClients";
import SingleVimeoPlayer from "../../components/SingleVimeoPlayer/SingleVimeoPlayer";
import { StyledGridRow } from "../../commons/Grid";
import useNewsletterListId from "../../hooks/useNewsletterListId";

const Academy = () => {
  const { 
    pageLabels, 
    pageImages,
    currentLanguageSuffix,
    i18nLink,
  } = useContext(DataContext);
  toast.configure();
  const { getListId } = useNewsletterListId();

  return (
    <>
      <Meta />

      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify([
            {
              "@context":"https://schema.org",
              "@type": "Service",
              "name": pageLabels.hero.title.replace(/<[^>]*>?/gm, ""),
              "brand": {
                "@type": "Brand",
                "name": "3Bee",
              },
              "description": pageLabels.hero.paragraph.replace(/<[^>]*>?/gm, ""),
            },
          ])}
        </script>
      </Helmet>

      <Hero3BC 
        title = {pageLabels.hero.title}
        image = "/2020/images/business/academy/mostrare_api_apiario.jpg"
        paragraph = {pageLabels.hero.paragraph}
        buttonText = {pageLabels.hero.cta}
        to = "#why"
        newsletterButtonText = {pageLabels.hero.newsletterButtonText}
        newsletterTitle = {pageLabels.hero.newsletterTitle}
        titleBackground = "transparent"
        lang={currentLanguageSuffix}
        success = "Welcome in 3Bee, Bzz"
        error="Error. Contact us on info@3bee.com"
        leftButtonProps={{
          text: pageLabels.hero.leftButtonProps.text,
          to: "https://www.3bee.com/call-4-people/", //! I tried to use /call-4-people/ but it doesn't work, Am I missing something???
        }}
        newsletterListType = "businessPage"
        allImages = {pageImages}
      />
      
      <Spacer size={10}/>

      <SectionTwoCol
        id="why"
        title={pageLabels.why.title}
        subtitle1={pageLabels.why.subtilte}
        cta={pageLabels.commons.askAdvice}
        to="#contacts"
        image="/2020/images/business/academy/beekeeper.jpg"
        topImage
      />
      
      <Spacer size={10}/>

      <Intro 
        bigText={pageLabels.activities.title}
        subtitle={pageLabels.activities.subtitle}
        textAlign="center"
      />
      <BusinessIconText
        items={[{
          image: "/2020/images/business/academy/impollination.svg",
          label: pageLabels.activities.education.label,
          text: pageLabels.activities.education.text,
        },{
          image: "/2020/images/business/academy/hive.svg",
          label: pageLabels.activities.beekeeper.label,
          text: pageLabels.activities.beekeeper.text,
        },{
          image: "/2020/images/business/academy/honey.svg",
          label: pageLabels.activities.tasting.label,
          text: pageLabels.activities.tasting.text,
        }]}
      />
      
      <Spacer size={10}/>
      
      <StyledGridRow noMargin>
        <SingleVimeoPlayer
          videoID="746464103"
          thumb="/2020/images/business/academy/academy_thumb.jpg"
        />
      </StyledGridRow>

      <Spacer size={10}/>
      
      <BusinessSlider items={pageLabels.slider.items.map(e => {
        return {
          ...e,
          theme: "white",
          cta: {
            label: pageLabels.commons.askAdvice,
            url: "#contacts",
          },
          ul: e.items,
        };
      })}/>

      <Spacer size={10}/>

      <BusinessClients 
        title={pageLabels.clients.title}
        subtitle={pageLabels.clients.subtitle}
        logos={[
          { image: "/2020/images/business/academy/clients/amazon.png", slug: "amzl-italia-1" },
          { image: "/2020/images/business/academy/clients/calzedonia.png", slug: null },
          { image: "/2020/images/business/academy/clients/ubisoft.png", slug: null },
          { image: "/2020/images/business/academy/clients/davines.png", slug: "loasi-di-davines-1" },
          { image: "/2020/images/business/academy/clients/ferrero.png", slug: "ferrero" },
          { image: "/2020/images/business/academy/clients/nen.png", slug: "nen-1" },
          { image: "/2020/images/business/academy/clients/pernod.png", slug: "pride-beestrees-1" },
          { image: "/2020/images/business/academy/clients/toyota-new.png", slug: "tmhit-1" },
        ]}
      />

      <Spacer size={10}/>

      <ContactFormPipe />
    </>
  );
};

export default Academy;
