import React, { useState } from "react";
import {
  Hero,
  HeroImageContainer,
  HeroHeader,
  Button,
  NewsletterBox,
} from "@3beehivetech/components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { toast } from "react-toastify";
import useNavigate from "../../hooks/useNavigate";
import useNewsletterListId from "../../hooks/useNewsletterListId";
import { StyledSubTitleHero } from "../../commons/Typography";
import {
  StyledNewsletter, StyledWrapperButton, StyledTitle, 
} from "./style.jsx";

export default (props) => {
  const { getListId } = useNewsletterListId();
  const [ displayImage, setDisplayImage ] = useState(true);
  const imageData = props.allImages.find((e) => e.path === props.image);
  toast.configure();

  const videoRefCallback = (node) => {
    setTimeout(() => {node?.play(), setDisplayImage(false);}, 4000);
  };

  return (
    <div>
      <Hero
        className="apply-typography"
        variant="divided"
        style="justify-content: space-between; align-items: flex-start"
      >
        <HeroHeader
          style={{
            marginTop: "140px",
            paddingBottom: "100px",
            alignSelf: "end",
            marginRight: "0px",
          }}
          title={
            <StyledTitle
              dangerouslySetInnerHTML={{ __html: props.title }}
              variant={props.isValentineSection}
            />
          }
          description={
            <StyledSubTitleHero
              dangerouslySetInnerHTML={{ __html: props.paragraph }}
            />
          }
          variant={props.titleBackground}
        >
          <StyledWrapperButton className="mt-8" variant={props.isValentineSection || props.isCourses}>
            {props.to && (<Button
              variant={props.buttonVariant}
              onClick={useNavigate(props.to)}
            >
              {props.buttonText}
            </Button>)}
            {props.to2 && (<Button
              variant={props.buttonVariant}
              onClick={useNavigate(props.to2)}
            >
              {props.buttonText2}
            </Button>)}
          </StyledWrapperButton>
        </HeroHeader>
        <HeroImageContainer
          className="imgHero"
          style={{ objectPosition: "rigth top" }}
          variant="shape"
          fading
        >
          <GatsbyImage
            style={{
              objectPosition: "right top", 
              transition: "all 1s linear", 
              display: "block", 
              transitionDelay: "2s",
              opacity: displayImage ? "1" : "0",
              zIndex: 5,
            }}
            loading="eager"
            layout="fullWidth"
            objectFit="cover"
            objectPosition="center"
            opacity="1"
            image={getImage(imageData)}
            alt={imageData.alt}
          />

          {props.url  && (
            <div style={{
              transitionDelay: "2s",
              transition: "all 1s linear", 
              position: "absolute",
              height: "100%", 
              objectFit: "cover", top: "0px",  zIndex: 4, 
              opacity: displayImage ? "0" : "1",
            }}>
              <video
                style="object-position: center; height: 100%; max-width: none;"
                controls="false" 
                playsinline="true" 
                muted="true" 
                loop="true"
                ref={videoRefCallback}
                preload="none"
              >
                <source src={props.url} />
              </video>
            </div>)}
          
        </HeroImageContainer>
      </Hero>
      {!props.noNewsletter && (
        <StyledNewsletter>
          <NewsletterBox
            style="z-index: 1 !important;"
            title={props.boxTitle}
            newsletterListId={getListId(props.newsletterListType)}
            newsletterButtonText={props.newsletterButtonText}
            newsletterTitle={props.newsletterTitle}
            lang={props.lang}
            inputProps={{ placeholder: "Email" }}
            leftButtonProps={{
              text: props.leftButtonProps.text,
              onClick: useNavigate(props.leftButtonProps.to),
            }}
            onSuccess={() =>
              toast.success(props.success, {
                containerId: "Alert",
                position: toast.POSITION.TOP_CENTER,
                autoClose: 10000,
              })
            }
            onError={() =>
              toast.error(props.error, {
                containerId: "Alert",
                position: toast.POSITION.TOP_CENTER,
                autoClose: 10000,
              })
            }
          />
        </StyledNewsletter>
      )}
    </div>
  );
};
