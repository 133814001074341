import { useEffect } from "react";

/**
 *
 * @param {() => void} cb
 * @param {{disabled?: boolean, timeout: number}} options
 * @example
 *  useTimeoutCallback(
 *    useCallback(() => cb(), []),
 *    { disabled: userInteraction || !autoSlide, timeout }
 *  )
 */

const useTimeoutCallback = (cb, { disabled, timeout = 10000 }) => {
  useEffect(() => {
    if (disabled) return;

    const id = setTimeout(() => {
      cb();
    }, timeout);

    return () => clearTimeout(id);
  }, [cb, disabled, timeout]);
};

export default useTimeoutCallback;
