import React from "react";
import {
  StyledIntro,
  StyledIntroTitle,
  StyledIntroSubtitle,
  StyledIntroBigText,
  StyledSmallText,
} from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";

const Intro = ({
  title,
  subtitle,
  bigText,
  id,
  text,
  marginBottom,
  textAlign,
  isLove,
  isValentine,
}) => {
  return (
    <StyledGridRow id={id} noMargin>
      <StyledIntro marginBottom={marginBottom} textAlign={textAlign}>
        {title && (
          <StyledIntroTitle
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {bigText && (
          <StyledIntroBigText 
            dangerouslySetInnerHTML={{ __html: bigText }}
            isLove={isLove}
            variant={isValentine}
          />
        )}

        {subtitle && (
          <StyledIntroSubtitle
            dangerouslySetInnerHTML={{ __html: subtitle }}
            variant={isValentine}
          />
        )}

        {text && (
          <StyledSmallText
            dangerouslySetInnerHTML={{ __html: text }}
            variant={isValentine}
          />
        )}
      </StyledIntro>
    </StyledGridRow>
  );
};

export default Intro;
