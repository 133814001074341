import styled, { css } from "styled-components";
import { BP } from "../../commons/Theme";
import { StyledTitleHero } from "../../commons/Typography";

export const StyledNewsletter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -60px;
  z-index: 2;
  position: relative;
  @media (${BP.ipad}) {
    margin-top: 50px;
  }
`;

export const StyledWrapperButton = styled.div`
  display: flex; 
  column-gap: 20px;
  ${(props) => 
    props.variant
    && css`
    button {
      padding: 18px 30px;
    }
    `}
`;

export const StyledTitle = styled(StyledTitleHero)`
  ${(props) => 
    props.variant
    && css`
      text-transform: unset !important;
      line-height: 120% !important;
      letter-spacing: 1px;
      @media (${BP.ipad}) {
        min-width: 450px;
      }
    `}
`;
